import * as React from "react";
import classNames from 'classnames';

import CardVideo from '../CardVideo';
import { CardVideoProps } from '../CardVideo/CardVideo';

import s from './PromoVideos.module.scss';

export interface PromoVideosProps {
    videoSquare: CardVideoProps;
    videoWide: CardVideoProps;
    pageType?: string;
}

const PromoVideos: React.FC<PromoVideosProps> = ({ videoSquare, videoWide, pageType }) => {
    const rootClasses = classNames(s.Root, !!pageType ? s[`Root--${pageType}`] : '');

    return (
        <div className={rootClasses}>
            <div className={s.Videos}>
                {videoSquare && (
                    <div className={s.VideoSquareWrapper}>
                        <div className={s.VideoSquareWrapperInner}>
                            <CardVideo {...videoSquare} />
                        </div>
                    </div>
                )}
                {videoWide && (
                    <div className={s.VideoWideWrapper}>
                        <div className={s.VideoWideWrapperInner}>
                            <CardVideo {...videoWide} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default PromoVideos
